.Button {
    /* background: linear-gradient(to right, #9B1C1B, #E87426)!important; */
    background: linear-gradient(to right,  #EA7772,rgb(148, 175, 245))!important;


  }
   .MuiDataGrid-columnHeaders {
    height: 30px !important; 
   min-height: 20px !important;
    
  }
  .checkout-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .card-element {
    display: block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }
  
  .pay-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pay-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
 